import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >



<path d="M4802 6749 c-177 -17 -350 -103 -547 -272 -239 -206 -434 -456 -570
-730 -76 -155 -95 -215 -95 -307 0 -87 15 -134 56 -180 46 -51 104 -78 184
-86 109 -11 240 34 418 142 41 25 77 43 80 40 2 -3 -30 -64 -72 -136 -42 -71
-76 -132 -76 -134 0 -2 -22 -41 -50 -85 -73 -117 -231 -408 -252 -464 -23 -60
-23 -98 1 -145 27 -52 65 -88 125 -116 44 -20 156 -43 156 -31 0 2 -9 20 -21
39 -41 70 -8 153 216 539 37 64 71 117 75 117 4 0 17 -28 30 -62 58 -163 234
-458 384 -643 164 -204 378 -386 532 -454 124 -56 165 -65 279 -65 103 0 114
2 175 31 75 37 117 77 153 148 34 67 53 175 36 206 -12 23 -12 22 -25 -19 -28
-96 -102 -151 -204 -152 -66 0 -182 30 -253 67 -153 77 -418 335 -594 578
-156 216 -332 518 -320 549 3 7 40 35 84 61 106 66 295 187 488 313 295 194
760 482 776 482 14 0 12 -6 -25 -73 -18 -34 -80 -147 -136 -252 -57 -104 -148
-273 -203 -375 -55 -102 -131 -239 -170 -305 -38 -66 -85 -151 -103 -188 -18
-37 -37 -73 -42 -80 -18 -23 -74 -182 -75 -213 -1 -44 20 -90 49 -103 43 -19
159 -14 215 10 27 12 49 24 49 26 0 3 -9 16 -19 30 -22 27 -21 35 16 108 89
173 488 625 752 852 113 97 119 86 29 -55 -33 -53 -96 -149 -138 -212 -115
-173 -248 -402 -286 -491 -45 -107 -62 -196 -43 -234 39 -80 129 -91 254 -31
68 32 95 54 229 188 83 84 196 202 251 262 99 108 126 121 87 42 -60 -121
-102 -248 -102 -306 0 -118 218 -240 342 -191 25 10 161 122 244 202 29 27 53
49 55 49 2 0 4 -35 4 -77 0 -64 4 -85 23 -116 66 -104 188 -86 332 49 54 51
205 219 237 263 2 2 6 2 9 -1 3 -4 -1 -19 -9 -35 -24 -45 -31 -117 -17 -175
26 -109 68 -161 141 -173 46 -8 74 8 224 129 93 74 540 521 540 539 0 22 -68
19 -112 -5 -24 -13 -116 -95 -206 -183 -249 -244 -278 -268 -300 -246 -24 24
183 391 570 1011 151 242 215 347 243 396 10 16 52 90 95 164 84 145 115 232
115 322 0 49 -4 60 -30 86 -47 48 -116 52 -235 15 -59 -19 -70 -31 -48 -56 35
-37 -8 -175 -105 -337 -21 -36 -124 -207 -229 -380 -104 -173 -237 -396 -295
-495 -148 -252 -151 -255 -165 -255 -7 0 -13 5 -13 10 0 6 -11 10 -24 10 -19
0 -36 -17 -79 -77 -57 -82 -243 -279 -336 -357 -97 -81 -95 -66 15 122 127
216 184 328 184 362 0 71 -52 138 -135 176 -91 41 -91 41 -99 -12 -7 -57 -64
-161 -157 -291 -60 -82 -171 -225 -189 -243 -3 -3 -30 -34 -60 -70 -95 -112
-114 -132 -158 -162 -27 -19 -52 -28 -70 -26 -25 3 -27 6 -26 53 1 85 106 294
252 499 76 108 293 326 353 354 81 39 178 23 266 -44 17 -13 36 -24 42 -24 16
0 24 83 12 126 -17 62 -72 119 -139 144 -49 19 -71 21 -152 17 -136 -8 -227
-48 -372 -165 -103 -83 -245 -252 -322 -384 -22 -38 -33 -48 -54 -48 -81 0
-98 -13 -327 -247 -121 -124 -221 -220 -223 -215 -9 27 210 404 362 624 139
203 148 218 143 261 -12 91 -108 167 -212 167 -39 0 -62 -7 -97 -29 -58 -37
-209 -171 -392 -348 -76 -74 -140 -132 -143 -129 -6 6 40 91 142 256 45 74 92
151 103 170 11 19 69 121 130 226 60 105 138 251 173 323 l64 131 75 32 c160
67 320 88 360 48 23 -23 24 -39 9 -110 -6 -28 -9 -54 -6 -57 3 -3 19 3 35 13
110 65 66 303 -81 434 -46 41 -64 48 -132 49 -156 3 -530 -171 -890 -413 -173
-116 -389 -269 -509 -358 -127 -96 -149 -112 -423 -331 -94 -75 -176 -137
-183 -137 -6 0 16 52 50 115 175 329 369 743 399 850 24 87 6 205 -42 276 -37
56 -68 76 -139 89 -29 6 -60 12 -68 14 -8 2 -52 -1 -98 -5z m130 -137 c-3 -46
-56 -207 -98 -299 -55 -121 -176 -363 -185 -368 -4 -3 -18 14 -32 38 -37 62
-91 97 -151 97 -46 0 -48 -1 -41 -22 23 -71 18 -101 -33 -228 -71 -178 -307
-456 -441 -521 -50 -24 -99 -25 -117 -1 -31 41 3 198 74 339 161 319 651 827
938 973 73 37 90 35 86 -8z"/>
<path d="M1247 6703 c-4 -3 -7 -298 -7 -655 l0 -648 655 0 655 0 0 655 0 655
-648 0 c-357 0 -652 -3 -655 -7z"/>
<path d="M9139 5862 c-77 -34 -110 -106 -119 -267 -4 -66 -13 -133 -21 -150
-14 -32 -89 -156 -99 -165 -3 -3 -13 -17 -22 -31 -9 -15 -74 -98 -144 -185
-192 -240 -214 -271 -214 -310 0 -72 49 -39 195 131 33 38 80 92 105 120 99
112 157 180 193 229 22 28 43 52 47 54 12 5 23 -73 15 -117 -6 -38 -66 -121
-86 -121 -20 0 -166 -106 -220 -159 -86 -87 -159 -236 -159 -327 0 -92 61
-193 144 -237 34 -18 57 -22 141 -22 94 0 104 2 175 36 135 65 216 157 264
301 52 154 44 350 -21 567 -53 177 -63 214 -63 239 0 36 29 92 71 139 47 52
55 81 40 148 -12 51 -34 80 -91 118 -25 17 -100 22 -131 9z m59 -173 c-1 -39
-6 -73 -10 -76 -10 -6 -38 59 -38 87 0 30 18 60 36 60 13 0 15 -13 12 -71z
m-102 -771 c-21 -219 -81 -352 -190 -425 -135 -92 -228 31 -148 195 49 102
268 301 331 302 11 0 12 -14 7 -72z"/>
<path d="M2635 5318 c-3 -7 -4 -204 -3 -438 l3 -425 435 0 435 0 3 438 2 437
-435 0 c-339 0 -437 -3 -440 -12z"/>
<path d="M8256 4317 c-22 -16 -18 -65 5 -80 47 -29 101 32 64 73 -21 23 -44
25 -69 7z"/>
<path d="M6910 4261 c0 -50 -3 -59 -20 -64 -15 -4 -20 -14 -20 -36 0 -24 4
-31 20 -31 19 0 20 -7 20 -125 l0 -125 40 0 40 0 0 125 0 125 25 0 c22 0 25 4
25 35 0 31 -3 35 -25 35 -24 0 -25 3 -25 60 l0 60 -40 0 -40 0 0 -59z"/>
<path d="M6240 4095 l0 -215 116 0 115 0 -3 38 c-2 20 -9 38 -15 38 -7 1 -40
2 -73 3 l-60 1 0 55 0 54 73 3 72 3 3 38 3 37 -75 0 -76 0 0 40 0 39 73 3 72
3 3 38 3 37 -115 0 -116 0 0 -215z"/>
<path d="M6540 4040 l0 -160 40 0 40 0 0 99 c0 84 3 102 21 125 27 34 73 36
90 4 7 -13 15 -69 17 -125 l5 -103 33 0 34 0 0 131 0 131 -29 29 c-24 23 -38
29 -73 29 -24 0 -54 -7 -67 -16 -22 -16 -24 -16 -33 0 -6 10 -22 16 -43 16
l-35 0 0 -160z"/>
<path d="M7152 4183 c-75 -37 -107 -162 -59 -234 27 -41 98 -79 147 -79 39 0
108 29 128 53 12 14 9 18 -18 32 -27 14 -33 14 -52 0 -49 -34 -148 -3 -148 46
0 18 8 19 126 19 l127 0 -5 44 c-6 53 -47 110 -90 125 -45 16 -118 13 -156 -6z
m146 -69 c40 -26 25 -34 -63 -34 -73 0 -84 2 -78 16 8 23 63 45 93 38 14 -3
35 -12 48 -20z"/>
<path d="M7450 4040 l0 -160 40 0 40 0 0 94 c0 125 13 162 56 157 10 -1 34 40
34 59 0 16 -53 12 -78 -6 -20 -14 -22 -14 -22 0 0 12 -10 16 -35 16 l-35 0 0
-160z"/>
<path d="M7660 3980 l0 -220 40 0 40 0 0 74 0 74 37 -19 c105 -54 213 23 213
151 0 56 -15 93 -54 129 -33 31 -110 40 -154 17 -38 -20 -42 -20 -42 -1 0 11
-11 15 -40 15 l-40 0 0 -220z m225 125 c33 -32 35 -98 3 -132 -32 -34 -99 -32
-127 3 -70 89 46 208 124 129z"/>
<path d="M8040 4040 l0 -160 40 0 40 0 0 94 c0 126 13 163 58 157 11 -2 32 37
32 60 0 15 -54 10 -78 -7 -20 -14 -22 -14 -22 0 0 12 -10 16 -35 16 l-35 0 0
-160z"/>
<path d="M8250 4041 l0 -161 40 0 41 0 -3 158 -3 157 -37 3 -38 3 0 -160z"/>
<path d="M8430 4183 c-34 -23 -40 -33 -40 -71 0 -25 8 -42 31 -63 17 -16 36
-29 43 -29 20 0 70 -46 62 -58 -9 -16 -61 -15 -87 1 -17 10 -22 9 -39 -13 -23
-30 -13 -54 31 -69 87 -30 168 11 169 86 0 39 -30 75 -89 106 -25 13 -46 28
-49 35 -6 20 28 26 62 10 30 -15 32 -14 50 10 19 25 19 25 -8 49 -33 28 -100
31 -136 6z"/>
<path d="M8729 4177 c-82 -55 -100 -158 -43 -239 50 -72 156 -90 232 -41 23
15 42 32 42 38 0 5 -13 16 -30 23 -25 12 -33 11 -52 -2 -35 -25 -94 -21 -123
9 -50 49 -38 55 105 55 129 0 130 0 130 23 0 38 -43 110 -79 134 -48 32 -135
32 -182 0z m161 -72 l25 -25 -88 0 c-48 0 -87 4 -87 8 0 11 30 37 50 45 27 11
74 -3 100 -28z"/>
<path d="M9067 4172 c-52 -54 -32 -103 71 -166 24 -15 41 -32 39 -39 -4 -12
-57 -14 -92 -3 -14 4 -25 -1 -38 -18 -17 -24 -16 -26 5 -45 29 -27 81 -36 129
-22 47 14 69 44 69 92 0 29 -7 43 -33 66 -18 16 -47 35 -65 42 -20 8 -32 21
-32 32 0 23 27 25 58 3 21 -15 24 -14 42 8 25 30 25 33 -6 57 -41 33 -113 29
-147 -7z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
